<template>
  <div class="flex-row-fluid col-lg-9" :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : ''">
    <!--begin::Card-->
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Segurança</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">Atualize sua senha de segurança</span>
        </div>
      </div>

      <OverlayLoader :is-submitting="isSubmitting">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Alert-->
          <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert">
            <div class="alert-icon">
              <span class="svg-icon svg-icon-3x svg-icon-danger">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                    <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                    <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </div>
            <div class="alert-text font-weight-bold">
              Troque sua senha pelo menos uma vez ao mês ou quando sentir necessidade.
              <br />

              A senha deve ter no mínimo 6 caracteres e no máximo 8.
              <br />
              Evite criar senhas óbvias, tipo data de nascimento, nomes de parentes, letras ou números repetidos, etc.
            </div>
            <div class="alert-close">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i class="ki ki-close"></i>
                </span>
              </button>
            </div>
          </div>
          <!--end::Alert-->

          <b-form class="form" @submit.stop.prevent="onSubmit">
            <!-- Input Actual Password -->
            <b-form-group label="Senha">
              <b-form-input
                v-model="$v.form.password.$model"
                class="form-control form-control-solid h-auto py-5 px-6 rounded"
                type="password"
                name="password"
                :state="validateState('password')"
              ></b-form-input>

              <b-form-invalid-feedback v-if="!$v.form.password.required">Senha é obrigatória.</b-form-invalid-feedback>

              <b-form-invalid-feedback v-if="!$v.form.password.minLength">
                A senha deve conter no mínimo 8 caracteres.
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Input New Password -->
            <b-form-group label="Nova Senha">
              <b-form-input
                v-model="$v.form.new_password.$model"
                class="form-control form-control-solid h-auto py-5 px-6 rounded"
                type="password"
                name="new_password"
                :state="validateState('new_password')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.form.new_password.required">
                Senha é obrigatória.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.new_password.minLength">
                A senha deve conter no mínimo 8 caracteres.
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- Input Re-Password New -->
            <b-form-group label="Confirmação de senha">
              <b-form-input
                v-model="$v.form.repeat_password.$model"
                class="form-control form-control-solid h-auto py-5 px-6 rounded"
                type="password"
                name="repeat_password"
                :state="validateState('repeat_password')"
              ></b-form-input>

              <b-form-invalid-feedback v-if="!$v.form.repeat_password.required">
                Nova senha é obrigatória.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.repeat_password.sameAsPassword">
                As senhas não coincidem.
              </b-form-invalid-feedback>
            </b-form-group>

            <!--begin::Action-->
            <div class="form-group d-flex justify-content-center border-top pt-10">
              <button
                ref="kt_login_signup_submit"
                type="submit"
                class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
              >
                Modificar Senha
              </button>
            </div>
            <!--end::Action-->
          </b-form>
        </div>
        <!--end::Body-->
      </OverlayLoader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    OverlayLoader
  },
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      form: {
        password: '',
        new_password: '',
        repeat_password: ''
      }
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    })
  },
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil' }, { title: 'Segurança' }]);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      const submitButton = this.$refs['kt_login_signup_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch('UPDATE_PASSWORD', this.$v.form.$model)
        .then((response) => {
          Swal.fire({
            title: response.message,
            icon: 'success',
            confirmButtonClass: 'btn btn-succcess'
          });

          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');

          this.resetForm();
        })
        .catch((error) => {
          Swal.fire({
            title: error,
            icon: 'error',
            confirmButtonClass: 'btn btn-succcess'
          });

          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    resetForm() {
      this.form = {
        password: null,
        new_password: null,
        repeat_password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  },
  validations: {
    form: {
      password: {
        required
      },
      new_password: {
        required,
        minLength: minLength(8)
      },
      repeat_password: {
        required,
        sameAsPassword: sameAs('new_password')
      }
    }
  }
};
</script>
